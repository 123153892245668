const Card = ({ image, link, linkText, linkBackground }) => (
  <section className="overflow-hidden custom-card">
    <img className="w-100" src={image} alt={linkText} />
    <header className="p-4 bg-gray">
      <a
        className="position-relative d-block p-4 text-white text-center text-decoration-none text-uppercase custom-card-btn"
        href={link}
        rel="nofollow"
        style={{ background: linkBackground }}
      >
        <h3 className="h1 text-truncate mb-0">{linkText}</h3>
      </a>
    </header>
  </section>
);

export default Card;
