const PromotionSection = ({
  tag: RootTag = 'div',
  className,
  children,
  headerChildren,
}) => (
  <RootTag className={`position-relative border-2 promotion-section${className ? ` ${className}` : ''}`}>
    <header>{headerChildren}</header>
    {children}
  </RootTag>
);

export default PromotionSection;
