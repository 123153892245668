import ClothingBrandFight1 from './images/fight/clothing-brand-fight-1.jpg';
import ClothingBrandFight2 from './images/fight/clothing-brand-fight-2.jpg';
import ClothingBrandFight3 from './images/fight/clothing-brand-fight-3.jpg';
import ClothingBrandFight4 from './images/fight/clothing-brand-fight-4.jpg';

const fight = [
  { name: 'Демонстрация № 1 бренда одежды «Fight»', image: ClothingBrandFight1 },
  { name: 'Демонстрация № 2 бренда одежды «Fight»', image: ClothingBrandFight2 },
  { name: 'Демонстрация № 3 бренда одежды «Fight»', image: ClothingBrandFight3 },
  { name: 'Демонстрация № 4 бренда одежды «Fight»', image: ClothingBrandFight4 },
];

export default fight;
