const BigLink = ({ link, text, uppercase = false, ...props }) => (
  <a
    className={`btn btn-primary text-truncate mw-100 py-3 px-4 py-sm-4 px-sm-5 fs-1 text-white big-link${uppercase ? ' text-uppercase' : ''}`}
    href={link}
    title={text}
    {...props}
  >{text}</a>
);

export default BigLink;
