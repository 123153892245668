const SocialNetworkLink = ({
  icon,
  iconWidth = '66.6%',
  iconContainerImage,
  linkHref,
  linkText,
  borderColor,
  primaryTextColor = false,
}) => (
  <div className="position-relative text-center">
    <div
      className={`position-relative mb-3${
        iconContainerImage
          ? ''
          : ' rounded-circle social-network-link-icon-container-basic'
      }`}
      style={{
        borderColor,
        ...(iconContainerImage
          ? {
            paddingBottom: '100%',
            backgroundImage: `url(${iconContainerImage})`,
          }
          : {}
        )
      }}
    >
      <img
        className="position-absolute top-50 start-50 translate-middle"
        src={icon}
        alt={linkText}
        style={{ width: iconWidth }}
      />
    </div>
    <a
      className={`stretched-link text-truncate d-block fs-5 fs-lg-3 text-decoration-none${primaryTextColor ? '' : ' text-body'}`}
      href={linkHref}
      title={linkText}
      rel="nofollow"
    >{linkText}</a>
  </div>
);

export default SocialNetworkLink;
