import TvoyDetskiyDenRozhdeniya from './images/offers/tvoy-detskiy-den-rozhdeniya.jpg';
import TvoyDenRozhdeniya from './images/offers/tvoy-den-rozhdeniya.jpg';
import DetskayaVecherinkaKhaggiVagiIKisiMisi from './images/offers/detskaya-vecherinka-khaggi-vagi-i-kisi-misi.jpg';

const offers = [
  {
    name: 'Твой детский день рождения',
    image: TvoyDetskiyDenRozhdeniya,
  },
  {
    name: 'Твой день рождения',
    image: TvoyDenRozhdeniya,
  },
  {
    name: 'Детская вечеринка «Хагги Ваги и Киси Миси»',
    image: DetskayaVecherinkaKhaggiVagiIKisiMisi,
  },
];

export default offers;
