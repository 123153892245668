import { useState, useRef, useEffect } from 'react'

const SectionDecorativeImage = ({
  style,
  classNameModifier,
  containerStyle,
}) => {
  // Need to set container height for working `top` and `bottom` of the image
  const [containerHeight, setContainerHeight] = useState();

  const imageRef = useRef();

  useEffect(() => {
    const handleWindowResize = () =>
      setContainerHeight(`${imageRef.current.offsetHeight}px`);

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <div
      className={`position-absolute section-decorative-image-container${
        classNameModifier
          ? ` section-decorative-image-container-${classNameModifier}`
          : ''
      }`}
      style={{
        ...containerStyle,
        height: containerHeight,
      }}
    >
      <div
        className={`position-relative section-decorative-image${
          classNameModifier
            ? ` section-decorative-image-${classNameModifier}`
            : ''
        }`}
        style={style}
        ref={imageRef}
      ></div>
    </div>
  );
};

export default SectionDecorativeImage;
