import sweeterImage from './images/cards/sweeter.jpg';
import aquaZoneImage from './images/cards/aqua-zone.jpg';
import dachaImage from './images/cards/dacha.jpg';
import quadBikesImage from './images/cards/quad-bikes.jpg';
import banquetsImage from './images/cards/banquets.jpg';
import gazebosImage from './images/cards/gazebos.jpg';

const cards = [
  {
    image: sweeterImage,
    link: 'https://lesnayarespublika.ru/#swe',
    linkText: 'Sweeter',
    label: null,
    decorativeImage: null,
  },
  {
    image: aquaZoneImage,
    link: 'https://lesnayarespublika.ru/#leto',
    linkText: 'Аквазона',
    label: null,
    decorativeImage: null,
  },
  {
    image: dachaImage,
    link: 'https://lesnayarespublika.ru/#dacha',
    linkText: 'Dacha',
    label: null,
    decorativeImage: null,
  },
  {
    image: quadBikesImage,
    link: 'https://lesnayarespublika.ru/#leto',
    linkText: 'Квадроциклы',
    label: null,
    decorativeImage: null,
  },
  {
    image: banquetsImage,
    link: 'https://lesnayarespublika.ru/#ban',
    linkText: 'Банкеты',
    label: null,
    decorativeImage: null,
  },
  {
    image: gazebosImage,
    link: 'https://lesnayarespublika.ru/#bes',
    linkText: 'Беседки',
    label: null,
    decorativeImage: null,
  },
];

export default cards;
