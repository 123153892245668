import { forwardRef } from 'react';

const NavigationButton = forwardRef((
  { image, width, height, className, imageClassName },
  ref,
) => (
  <button
    className={`p-0 bg-transparent border-0 slider-navigation-button${
      className
        ? ` ${className}`
        : ''
      }`
    }
    ref={ref}
  >
    <span
      className={`d-inline-block${imageClassName ? ` ${imageClassName}` : '' }`}
      style={{ backgroundImage: `url(${image})` }}
    ></span>
  </button>
));

export default NavigationButton;
