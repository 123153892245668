const PromotionLink = ({ className, href, text, ...props }) => (
  <a
    className={`btn text-truncate w-100 fw-bolder text-white text-uppercase border-0 promotion-link promotion-link-with-text${className ? ` ${className}` : ''}`}
    href={href}
    title={text}
    rel="nofollow"
    {...props}
  >{text}</a>
);

export default PromotionLink;
