import './App.scss';
import pageBg from './content/images/page-bg.svg';
import logo from './content/images/logo.svg';
import trianglesImage from './content/images/decorative-images/triangles.png';
import treeImage from './content/images/decorative-images/tree.svg';
import spruceImage from './content/images/decorative-images/spruce.svg';
import cards from './content/cards';
import Card from './components/Card';
import SectionDecorativeImage from './components/SectionDecorativeImage';
import SocialNetworkLink from './components/SocialNetworkLink';
import vkLogo from './content/images/vk.svg';
import telegramLogo from './content/images/telegram.svg';
import firstSliderSlides from './content/first-slider-slides';
import offers from './content/offers';
import Slider from './components/Slider';
import BigLink from './components/BigLink';
import AltaiPalaceFight from './sections/AltaiPalaceFight';
import AltaiPalaceGroupLogo from './content/images/altai-palace-group-logo.svg';

function App() {
  return (
    <div className="min-vh-100 overflow-hidden page-bg" style={{ backgroundImage: `url(${pageBg})` }}>
      <header className="container-lg g-0 position-relative">
        <div className="row g-0 position-relative justify-content-center section masthead">
          <div className="col-6">
            <div className="text-center">
              <img className="img-fluid logo-img" src={logo} alt="Парк «Лесная Республика»" />
            </div>
          </div>
          <div className="col-12" />
          <div className="col-6">
            <div className="text-center">
              {/* @todo `background-image`? */}
              <img className="img-fluid mb-4" src={trianglesImage} alt="" />
            </div>
          </div>
          <div className="col-12">
            {/* `h1` is not used because of irrelevant text. */}
            <div className="px-4 fw-lighter lh-sm text-primary text-center masthead-heading">ВОЗДУХ. ЛЕС. ЛЮБОВЬ.</div>
          </div>
          <div
            className="position-absolute w-100 h-100"
            style={{ display: 'contents' }}
          >
            {[
              {
                image: treeImage,
                width: '13.5%',
                top: '11%',
                left: '-3%',
              },
              {
                image: spruceImage,
                width: '7%',
                top: '4%',
                left: '29%',
              },
              {
                image: treeImage,
                width: '22%',
                top: '4%',
                left: '78%',
              },
              {
                image: spruceImage,
                width: '14%',
                top: '44%',
                left: '5%',
              },
              {
                image: spruceImage,
                width: '7%',
                top: '46%',
                left: '75%',
              },
              {
                image: treeImage,
                width: '13%',
                top: '75%',
                left: '90%',
              },
            ].map(({ image, width, top, left }) => (
              <img
                className="position-absolute"
                src={image}
                alt=""
                style={{ top, left, width }}
              />
            ))}
          </div>
        </div>
      </header>
      <main className="container-lg g-0 position-relative">
        <div className="row gx-0 justify-content-center section cards-section">
          <div className="col-11">
            <div className="row gy-4 gx-1 justify-content-center">
              {cards.map(({ image, link, linkText, label, decorativeImage }) => (
                <div className="col-6" key={linkText}>
                  <div className="position-relative custom-card-container">
                    {label &&
                      <div className="position-absolute top-0 start-50 d-flex align-items-center justify-content-center fs-5 text-white bg-red rounded-circle translate-middle custom-card-label">{label}</div>
                    }
                    {decorativeImage &&
                      <SectionDecorativeImage
                        style={decorativeImage.style}
                        containerStyle={decorativeImage.containerStyle}
                      />
                    }
                    <Card
                      image={image}
                      link={link}
                      linkText={linkText}
                      linkBackground="#516c3a"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row position-relative justify-content-center section social-network-links-and-site-links-section">
          <div className="col-11">
            <nav className="row row-cols-2 row-cols-sm-3 row-cols-md-4 gy-4 justify-content-center mb-4 mb-sm-5 social-network-links-row">
              <div className="col">
                <SocialNetworkLink
                  icon={telegramLogo}
                  linkHref="https://t.me/lesnaya_respublika"
                  linkText="Telegram"
                  iconWidth="100%"
                  borderColor="#ccc"
                  primaryTextColor
                />
              </div>
              <div className="col">
                <SocialNetworkLink
                  icon={vkLogo}
                  linkHref="https://vk.com/parkles"
                  linkText="VK"
                  borderColor="#ccc"
                  primaryTextColor
                />
              </div>
            </nav>
          </div>
          <div
            className="position-absolute w-100 h-100"
            style={{ display: 'contents' }}
          >
            {[
              {
                image: treeImage,
                width: '13%',
                top: '-56%',
                left: '-2%',
              },
              {
                image: spruceImage,
                width: '7%',
                top: '-23%',
                left: '78%',
              },
              {
                image: spruceImage,
                width: '13%',
                top: '18%',
                left: '8%',
              },
              {
                image: treeImage,
                width: '13%',
                top: '41%',
                left: '89%',
              },
            ].map(({ image, width, top, left }) => (
              <img
                className="position-absolute"
                src={image}
                alt=""
                style={{ top, left, width }}
              />
            ))}
          </div>
        </div>
        {firstSliderSlides.length > 0 &&
          <div className="row gx-0 justify-content-center section first-slider-section">
            <div className="col-12">
              <Slider slides={firstSliderSlides} />
            </div>
            <div className="col-8">
              <div className="text-center">
                <BigLink
                  link="https://wa.me/79234779977"
                  text="Забронировать"
                  uppercase
                  rel="nofollow"
                />
              </div>
            </div>
          </div>
        }
        {offers.length > 0 &&
          <div className="row gx-0 justify-content-center section offers-section">
            <div className="col-12">
              <Slider slides={offers} />
            </div>
            <div className="col-8">
              <div className="text-center">
                <BigLink
                  link="#"
                  text="Все предложения"
                  uppercase
                />
              </div>
            </div>
          </div>
        }
        <AltaiPalaceFight
          linkBackground="#516c3a"
        />
        <div className="row gx-0 position-relative justify-content-center section altai-palace-group-section">
          <div className="col-9">
            <div className="mb-2 fs-1 text-center">BY</div>
            <img className="img-fluid mb-5" src={AltaiPalaceGroupLogo} alt="Altai Palace Group" />
          </div>
          <div className="col-11">
            <div className="text-center">
              <a
                className="btn w-100 fw-bolder text-white text-uppercase border-0 altai-palace-group-link"
                href="https://lesnayarespublika.ru/"
              >Перейти на сайт</a>
            </div>
          </div>
          <div
            className="position-absolute w-100 h-100"
            style={{ display: 'contents' }}
          >
            {[
              {
                image: treeImage,
                width: '13%',
                top: '-8%',
                left: '-3%',
              },
              {
                image: spruceImage,
                width: '10%',
                top: '-17%',
                left: '27%',
              },
              {
                image: treeImage,
                width: '10%',
                top: '-9%',
                left: '48%',
              },
              {
                image: treeImage,
                width: '22%',
                top: '-18%',
                left: '78%',
              },
              {
                image: treeImage,
                width: '10%',
                top: '13%',
                left: '15%',
              },
              {
                image: spruceImage,
                width: '7%',
                top: '11%',
                left: '63%',
              },
              {
                image: spruceImage,
                width: '7%',
                top: '37%',
                left: '2%',
              },
              {
                image: treeImage,
                width: '13%',
                top: '32%',
                left: '87%',
              },
            ].map(({ image, width, top, left }) => (
              <img
                className="position-absolute"
                src={image}
                alt=""
                style={{ top, left, width }}
              />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
