import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper';
import NavigationButton from './NavigationButton';
import arrowLeftIcon from './../../content/images/arrow-left.svg';
import arrowRightIcon from './../../content/images/arrow-right.svg';

const Slider = ({ slides }) => {
  if (0 === slides.length) {
    return null;
  }

  const slidesPerView = 1 === slides.length ? 1 : 1.075;
  const hasNavigation = slides.length > 1;
  const navigationProps = hasNavigation
    ? {
      modules: [Navigation],
      navigation: {
        // @todo Use refs?
        prevEl: '.slider-navigation-button-prev',
        nextEl: '.slider-navigation-button-next',
        disabledClass: 'opacity-50',
      },
    }
    : {}
  ;

  return (
    <>
      <Swiper
        slidesPerView={slidesPerView}
        centeredSlides={true}
        centeredSlidesBounds={true}
        spaceBetween={16}
        breakpoints={{
          576: {
            spaceBetween: 24,
          },
        }}
        {...navigationProps}
        className="px-3 px-sm-4 slider"
      >
        {slides.map(({ name, image }) => (
          <SwiperSlide key={name}>
            <img
              className="w-100"
              src={image}
              alt={name}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {hasNavigation &&
        <div className="d-flex justify-content-center slider-navigation-button-container">
          <NavigationButton
            image={arrowLeftIcon}
            className="me-2 me-sm-3 slider-navigation-button-prev"
            imageClassName="slider-navigation-button-image"
          />
          <NavigationButton
            image={arrowRightIcon}
            className="ms-2 ms-sm-3 slider-navigation-button-next"
            imageClassName="slider-navigation-button-image"
          />
        </div>
      }
    </>
  );
};

export default Slider;
