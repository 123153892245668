import PromotionSection from './../components/PromotionSection';
import PromotionLink from './../components/PromotionLink';
import fightLogo from './../content/images/fight-logo.svg';
import fight from './../content/fight';
import SectionDecorativeImage from './../components/SectionDecorativeImage';
import ball3 from './../content/images/decorative-images/ball-3.png';

const AltaiPalaceFight = ({ linkBackground, withDecorativeImage = false }) => (
  <div className="row justify-content-center section altai-palace-fight-section">
    <div className="col-11">
      <PromotionSection
        tag="article"
        className="pb-5 border-black"
        headerChildren={
          <>
            <div className="row justify-content-center">
              <div className="col-9 col-lg-8">
                <img
                  className="promotion-section-img-heading"
                  src={fightLogo}
                  alt="Бренд одежды «Fight»"
                />
              </div>
            </div>
            <PromotionLink
              style={{ background: linkBackground }}
              href="https://www.wildberries.ru/brands/fight"
              text="Посетить магазин"
            />
          </>
        }
      >
        <div className="row row-cols-2 gy-3 gx-3 justify-content-center mb-5 fight-images-row">
          {fight.map(({ name, image }) => (
            <div key={name} className="col">
              <img
                className="img-fluid promotion-section-img"
                src={image}
                alt={name}
              />
            </div>
          ))}
        </div>
        <h2 className="display-4 mb-0 fw-normal text-center text-pre-line">{`Стильная одежда Fight
        для комфортного отдыха!`}</h2>
        {withDecorativeImage &&
          <SectionDecorativeImage
            classNameModifier="bottom-left-of-fight-promotion-section"
            containerStyle={{ bottom: 0, left: '15%' }}
            style={{
              paddingBottom: '100%',
              backgroundImage: `url(${ball3})`,
            }}
          />
        }
      </PromotionSection>
    </div>
  </div>
);

export default AltaiPalaceFight;
